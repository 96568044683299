.regular-button {
    width: 100%;
    height: 56px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Purple-100, #F8F9FE);
    backdrop-filter: blur(4px);
    border: none;
    cursor: pointer;

    color: var(--Purple-500, #7E85F9);
    text-align: center;
    font-family: Gilroy, sans-serif; /* Use Gilroy with a fallback to sans-serif */
    font-size: 16px;
    font-weight: 700;
    line-height: normal; /* Adjust if needed */
    margin: 10px 0; /* Space around the button */

    display: flex;
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */

    padding-left: 20px;
    padding-right: 20px;
}
