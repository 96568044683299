.submit-button {
    border-radius: 8px;
    background: var(--Purple-200, #E5E7FE);
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 362px;
    height: 56px;
    flex-shrink: 0;

    color: var(--White-Clear, #FFF);
    text-align: center;

    /* Paragraph Accent */
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: flex;
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
}

.submit-button.enabled {
    background: #7E85F9;
}

.submit-button svg {
    margin-left: 5px;
}