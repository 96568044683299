.thank-you-page {
    text-align: center;
    padding: 20px;

    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px; /* Adjust based on your design */
    margin: auto;
}

.download-area {
    margin-top: 20px;
    background: var(--Purple-500, #7E85F9);
    backdrop-filter: blur(10px);
    padding-bottom: 20px;
    padding-top: 20px;
}

.download-area a {
    margin: 0 10px;
}

.download-area img {
    height: 50px; /* Adjust as needed */
    width: auto;
}

/* Additional styles as needed */
