.danger-button {
    border-radius: 8px;
    background: var(--Purple-200, #FEBE98);
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 362px;
    height: 56px;
    flex-shrink: 0;

    color: var(--White-Clear, #FFF);
    text-align: center;

    /* Paragraph Accent */
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: flex;
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    margin: 10px 0; /* Space around the button */
}

.danger-button.enabled {
    background: #FEBE98;
}

.danger-button svg {
    margin-left: 5px;
}
