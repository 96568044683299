.social-sign-in {
    background-color: #fff;
    border: none; /* Remove the border */
    border-radius: 8px;
    padding: 10px;
    margin-top: 15px; /* Spacing after the checkbox */
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    text-decoration: none; /* In case it's a link */
}

.social-sign-in img {
    margin-right: 10px;
}

.social-sign-in-buttons-container {
    display: flex;  /* Enables Flexbox */
    justify-content: center; /* Horizontally center the items in the container */
    gap: 3px; /* Optional: Adds some space between the buttons */

}
