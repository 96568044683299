/* QuizQuestion.css */

.quiz-question {
    margin: 20px;
    padding: 20px;
}

.question {
    margin-bottom: 15px;
    color: var(--Dark-1000, #011838);
    text-align: center;

    /* Header. Third level. */
    font-family: Gilroy, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 122.222% */
    letter-spacing: 0.36px;
}

.choices {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align items if needed */
}

.choice-button {
    width: 342px;
    height: 58px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Purple-100, #F8F9FE);
    backdrop-filter: blur(4px);
    color: var(--Purple-500, #7E85F9);
    text-align: center;
    font-family: Gilroy, sans-serif; /* Fallback to sans-serif if Gilroy is not available */
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px; /* Spacing between buttons */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.choice-button:hover {
    background: var(--Purple-500, #7E85F9);
    color: var(--Purple-100, #F8F9FE);
}
