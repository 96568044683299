.otp-form {
    display: flex;
    flex-direction: column;
    max-width: 400px; /* Adjust based on your design */
    margin: auto;
    padding: 20px;
}

.otp-form input[type="text"] {
    /* Similar input styles as in the sign-up form */
}

.otp-input {
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
    margin: 0 5px;
    text-align: center;
    font-size: 20px; /* Adjust as needed */
}

.otp-input.invalid {
    border: 1px solid #F00; /* Red border for valid inputs */
    background: rgba(242, 243, 254, 0.50);
    backdrop-filter: blur(4px);
}

.otp-inputs-container {
    display: flex; /* Align OTP inputs horizontally */
    justify-content: start;
    margin-bottom: 20px; /* Space between inputs and buttons */
}

.otp-buttons-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
    width: 100%;
}

.request-new-code {
    background-color: transparent;
    border: none;
    color: var(--Dark-1000, #011838);
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
    padding-bottom: 200px;
}

.request-new-code.disabled {
    /* Disabled state styles */
    color: var(--Dark-500, #8B8F9B);
    /* No need to repeat styles that don't change */
}
.otp-form-form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns children (header and form) to the start (left) */
    max-width: 400px; /* Adjust based on your design, should match form width */
    margin: auto; /* Centers the container */
    padding: 20px; /* Adjust as needed */
}

.otp-form-form-header {
    color: #011838;
    padding-left: 20px;
    font-family: Gilroy, sans-serif; /* Use Gilroy with a fallback to sans-serif */
    font-size: 32px;
    font-weight: 700;
    text-align: left; /* Align text to the left */
    margin-bottom: 20px; /* Adjust as needed */
    width: 100%; /* Ensures the header takes full width of the container */

}

.otp-form-form-description {
    color: var(--Dark-500, #8B8F9B);
    padding-left: 20px;
    padding-bottom: 100px;
    width: 238px;
    font-family: Gilroy, sans-serif; /* Fallback to sans-serif if Gilroy is not available */
    font-size: 14px;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.28px;
    margin-bottom: 20px; /* Adjust as needed */
    text-align: left; /* Align to the left */
}

/* Add media queries for responsiveness if needed */

/* Responsive design */
@media (max-width: 768px) {
    .otp-form {
        width: 100%;
        padding: 10px;
    }
}
