.loading-cloak {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.40);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    z-index: 9999;
}

.loading-indicator {
    position: relative; /* Container for the SVGs */
    width: 240px;
    height: 10px;
}

.moving-part {
    position: absolute;
    top: -.9px; /* Adjust to center vertically within the loading area */
    animation: moveLoadingPart 2s linear infinite;
    z-index: 10; /* Ensure it's above the static part */
}

@keyframes moveLoadingPart {
    0% { left: -00px; }
    100% { left: 140px; }
}
