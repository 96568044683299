.sign-in-form {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust based on your design */
  margin: auto;
  padding: 20px;
}

.sign-in-input-field {
  margin-bottom: 10px;
  border-radius: 8px;
  width: 342px;
  height: 44px;
  flex-shrink: 0;
  border: 1px solid #DDD; /* Default border color */
  padding: 10px;
}

.sign-in-input-field.invalid {
  border: 1px solid #F00; /* Red border for valid inputs */
  background: rgba(242, 243, 254, 0.50);
  backdrop-filter: blur(4px);
}

.sign-in-form-label {
  color: var(--Dark-1000, #011838);
  text-align: left;
  font-family: Gilroy, sans-serif; /* Fall back to sans-serif if Gilroy is not available */
  font-size: 10px;
  font-weight: 700;
  line-height: 14px; /* 140% */
  letter-spacing: 0.3px;
  margin-bottom: 5px; /* Add some space between the label and input */
  display: block; /* Ensure it takes the full width */
}

.sign-in-social-sign-in {
  background-color: #fff;
  border: none; /* Remove the border */
  border-radius: 8px;
  padding: 10px;
  margin-top: 15px; /* Spacing after the checkbox */
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  text-decoration: none; /* In case it's a link */
}

.sign-in-social-sign-in img {
  margin-right: 10px;
}

.sign-in-social-sign-in-buttons-container {
  display: flex;  /* Enables Flexbox */
  justify-content: left; /* Horizontally center the items in the container */
  gap: 3px; /* Optional: Adds some space between the buttons */
}


.sign-in-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children (header and form) to the start (left) */
  max-width: 400px; /* Adjust based on your design, should match form width */
  margin: auto; /* Centers the container */
  padding: 20px; /* Adjust as needed */
}

.sign-in-form-header {
  color: #011838;
  padding: 20px;
  font-family: Gilroy, sans-serif; /* Use Gilroy with a fallback to sans-serif */
  font-size: 32px;
  font-weight: 700;
  text-align: left; /* Align text to the left */
  margin-bottom: 20px; /* Adjust as needed */
  width: 100%; /* Ensures the header takes full width of the container */
}

.error-message {
  color: var(--Red-500, #F00);
  text-align: left;

  /* Mini Accent */
  font-family: Gilroy, sans-serif; /* Fall back to sans-serif if Gilroy is not available */
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 140% */
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}


/* Responsive design */
@media (max-width: 768px) {
  .sign-in-form {
    width: 100%;
    padding: 10px;
  }
}
