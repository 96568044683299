.sign-up-form {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust based on your design */
  margin: auto;
  padding: 20px;
}

.sign-up-input-field {
  margin-bottom: 10px;
  border-radius: 8px;
  width: 342px;
  height: 44px;
  flex-shrink: 0;
  border: 1px solid #DDD; /* Default border color */
  padding: 10px;
}

.sign-up-input-field.invalid {
  border: 1px solid #F00; /* Red border for valid inputs */
  background: rgba(242, 243, 254, 0.50);
  backdrop-filter: blur(4px);
}


.sign-up-checkbox-container {
  margin-bottom: 15px;
  position: relative;
  padding-left: 40px; /* Adjusted to accommodate the width of the checkbox */
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  height: 32px; /* Make this equal to the height of your custom checkbox */
}

/* Hide the default checkbox */
.sign-up-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Custom checkbox styles */
.sign-up-checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: var(--Purple-100, #F8F9FE);
  border-radius: 8px;
  backdrop-filter: blur(4px);
}

/* Style for checked state */
.sign-up-checkbox-container input:checked ~ .checkmark {
  background-color: #050505;
}

/* Optional: Style for the checkmark icon */
.sign-up-checkbox-container input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 8px;
  top: 8px;
  width: 10px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Label text alignment */
.sign-up-checkbox-container label {
  position: relative;
  line-height: 32px; /* Match the height of your checkbox */
}

.sign-up-form-label {
  color: var(--Dark-1000, #011838);
  text-align: left;
  font-family: Gilroy, sans-serif; /* Fall back to sans-serif if Gilroy is not available */
  font-size: 10px;
  font-weight: 700;
  line-height: 14px; /* 140% */
  letter-spacing: 0.3px;
  margin-bottom: 5px; /* Add some space between the label and input */
  display: block; /* Ensure it takes the full width */
}

.sign-up-google-sign-in {
  background-color: #fff;
  border: none; /* Remove the border */
  border-radius: 8px;
  padding: 10px;
  margin-top: 15px; /* Spacing after the checkbox */
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  text-decoration: none; /* In case it's a link */
}

.sign-up-google-sign-in img {
  margin-right: 10px;
}

.sign-up-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children (header and form) to the start (left) */
  max-width: 400px; /* Adjust based on your design, should match form width */
  margin: auto; /* Centers the container */
  padding: 10px; /* Adjust as needed */
}

.sign-up-form-header {
  color: #011838;
  font-family: Gilroy, sans-serif; /* Use Gilroy with a fallback to sans-serif */
  font-size: 32px;
  font-weight: 700;
  padding: 20px;
  text-align: left; /* Align text to the left */
  margin-bottom: 5px; /* Adjust as needed */
  width: 100%; /* Ensures the header takes full width of the container */
}

.sign-up-form-description {
  color: var(--Dark-500, #8B8F9B);
  padding: 20px;
  width: 238px;
  font-family: Gilroy, sans-serif; /* Fallback to sans-serif if Gilroy is not available */
  font-size: 14px;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.28px;
  margin-bottom: 20px; /* Adjust as needed */
  text-align: left; /* Align to the left */
}

/* Responsive design */
@media (max-width: 768px) {
  .sign-up-form {
    width: 100%;
    padding: 10px;
  }
}
