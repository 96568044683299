.progress-bar-container {
    width: 90%;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.progress-top-section {
    display: flex;
    justify-content: space-between; /* Aligns one item to the left and the other to the right */
    align-items: center;
    margin-bottom: 10px; /* Spacing between the top section and the progress bar */
}

.progress-label {
    color: var(--Purple-500, #7E85F9);
    text-align: left;
    font-family: Gilroy, serif;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
}

.progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.progress {
    height: 10px;
    background-color: var(--Purple-500, #7E85F9);
    border-radius: 4px;
    width: 0%; /* Initial width */
}


.quizTitle {
    padding-left: 20px;
}

.public-quiz-form-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align children */
    margin: auto;
    padding: 20px;
    max-width: 400px; /* Adjust based on your design, should match form width */
}

.public-quiz-form {
    display: flex;
    flex-direction: column;
    max-width: 400px; /* Adjust based on your design */
    margin: auto;
    padding: 20px;
}
@media (max-width: 768px) {
    .public-quiz-form {
        width: 100%;
        padding: 10px;
    }
}
